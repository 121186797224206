// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
@import 'node_modules/ngx-sharebuttons/themes/default.scss';
@import 'node_modules/ngx-sharebuttons/themes/material.scss';
// @import 'ngx-sharebuttons/themes/material/material-light-theme';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$brf-news-v2-primary: mat-palette($mat-indigo);
$brf-news-v2-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$brf-news-v2-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$brf-news-v2-theme: mat-light-theme(
  (
    color: (
      primary: $brf-news-v2-primary,
      accent: $brf-news-v2-accent,
      warn: $brf-news-v2-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($brf-news-v2-theme);

@font-face {
  font-family: 'Arial';
  src: url('https://cdn.brfmappen.se/public/fonts/Arial.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Arial Bold';
  src: url('https://cdn.brfmappen.se/public/fonts/ArialBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger Bold';
  src: url('https://cdn.brfmappen.se/public/fonts/Frutiger_bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FrutigerLTStd-LightCn';
  src: url('https://cdn.brfmappen.se/public/fonts/FrutigerLTStd-LightCn.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('https://cdn.brfmappen.se/public/fonts/MyriadPro-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro-SemiboldCond';
  src: url('https://cdn.brfmappen.se/public/fonts/MyriadPro-SemiboldCond.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'PTSans-Bold';
  src: url('https://cdn.brfmappen.se/public/fonts/PTSans-Bold.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'PTSans-BoldItalic';
  src: url('https://cdn.brfmappen.se/public/fonts/PTSans-BoldItalic.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'PTSans-Italic';
  src: url('https://cdn.brfmappen.se/public/fonts/PTSans-Italic.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'PT Sans';
  src: url('https://cdn.brfmappen.se/public/fonts/PTSans-Regular.ttf') format('opentype');
  font-display: swap;
}
/* You can add global styles to this file, and also import other style files */

html,
body {
  background-color: #ffffff;
  font-family: 'PT Sans', sans-serif;
  color: #231f20;
}

body {
  margin-bottom: 60px;
}

.single-article .article-content img {
  max-width: 100% !important;
  height: auto;
  object-fit: scale-down;
}

.single-article .article-content iframe,
.single-article .article-content table {
  max-width: 100%;
}

.playIcon {
  font-size: 45px;
  color: #6da6da;
  position: absolute !important;
  top: calc(50% - 25px);
  left: calc(50% - 20px);
  font-weight: 900;
  background: black;
  height: 55px !important;
  width: 85px !important;
  border-radius: 10px;
  border: 5px solid black;
  cursor: pointer;
  border-left: 20px;
  border-right: 20px;
  text-align: center;
}

.playIconSmall {
  font-size: 25px;
  position: absolute !important;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  font-weight: 900;
  height: 35px !important;
  width: 35px !important;
  border: 5px solid black;
  cursor: pointer;
}

.sb-wrapper {
  width: 24px !important;
  min-width: 0 !important;
  min-height: 0 !important;
  height: 15px !important;
  padding: 8px 0px;
  // You can get default button color using the CSS variable
  background-color: var(--button-color);
  // Content wrapper
  .sb-button,
  .sb-content {
    width: 30px !important;
    // Icon wrapper
    .sb-icon {
      margin: 0px 5px 0 0px !important;
      display: inline-flex !important;
      min-width: 0px !important;
      font-size: 12px !important;
      //padding: 9px 0 10px;
    }
    // Text wrapper
    .sb-text {
    }
  }
}

.align-center {
  text-align: center;
}

.no-dropdown-icon:after {
  display: none;
}

.mat-select-panel {
  border: 1px #dee2e6 solid;
  background-color: #ffffff;

  .mat-option:hover {
    background-color: #efefef;
  }
}

.supplier-search {
  .mat-paginator-navigation-previous,
  .mat-paginator-navigation-next {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.mat-tooltip-show {
  background-color: rgba(50, 50, 50, 0.8);
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 767.99px) {
  .mw-4 {
    max-width: 25%;
  }

  .mw-3 {
    max-width: 33%;
  }

  .mw-2 {
    max-width: 50%;
  }
}

.sb-group .sb-wrapper {
  font-size: 12px !important;
}

.light-shadow {
  box-shadow: 0 0.4rem 0.6rem #00000015 !important;
}

.search-sp .tab-header .mat-expansion-indicator::after {
  color: #ffffff;
}

.search-sp .mat-expansion-panel-body {
  padding: 0;
}

.heightClass180 iframe,
.heightClass300 iframe,
.resize iframe {
  max-width: 100% !important;
}

.clickable {
  cursor: pointer;
}

a {
  color: #6da6da;
  &:hover {
    color: #5296d3;
  }
}

.new-flow-header {
  margin: 0;
  background: #58595b;
  color: #fff;
  font-family: 'MyriadPro-SemiboldCond';
  font-size: 20pt;
  padding: 5px 20px;
  position: relative;
  line-height: 28px;
}

.mat-dialog-container {
  background-color: #fff;
}

.carousel-inner {
  padding: 0 15px;
}

.carousel-item {
  display: block;
}

#news-carousel-news {
  .ngucarousel-items {
    margin: 0 -10px;
    padding-bottom: 13px;
  }

  //this cheat for showing shadow
  .ngucarousel {
    margin: 0 -10px;
    padding: 0 10px;
  }
}

#load-more-rnd-news .mat-progress-spinner circle,
#load-more-rnd-news .mat-spinner circle {
  stroke: #6da6da;
}

.tooltip-class {
  white-space: pre-line !important;
}

.digital-business-info-news .digital-membership-title {
  margin: 0 0 10px 0 !important;
}

.profile-news .mat-progress-spinner circle,
.profile-news .mat-spinner circle {
  stroke: #6da6da;
}

.cc-btn {
  text-decoration: none;
}

@media (min-width: 575px) and (max-width: 900px) {
  .social-handles .sb-group .sb-button {
    --sb-margin: 0.4125em 0.11em;
  }
}

@media (max-width: 325px) {
  .social-handles .sb-group .sb-button {
    --sb-margin: 0.4125em 0.11em;
  }

  .news-type-wide .news-desc {
    padding: 15px 12px 5px !important;
    p.date {
      font-size: 9pt;
    }
  }
}
